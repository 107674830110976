
import http from "@/http";
import { Company, Snackbar } from "@/interfaces";
import Vue from "vue";

export interface DataType {
  company?: Company;
  valid: boolean;
}

export default Vue.extend({
  data(): DataType {
    return {
      company: undefined,
      valid: false,
    };
  },
  computed: {
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"],
      };
    },
    items(): string[] {
      return ["クリーニング", "コールセンター", "巡回"];
    },
    breadcrumbs() {
      if (!this.company) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "業者",
          disabled: false,
          to: "/companies",
        },
        {
          text: this.company.name,
          disabled: true,
        },
      ];
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    try {
      const { data } = await http.get<Company>(`companies/${id}`);
      this.company = data;
    } catch (error) {
      const snackbar: Snackbar = {
        show: true,
        content: "見つかりません",
        color: "error",
      };

      this.$store.commit("setSnackbar", snackbar);
    }
  },
  methods: {
    async submit() {
      if (!this.company) {
        return;
      }

      const { id } = this.$route.params;

      const { email, name, industries } = this.company;

      await http.put(`companies/${id}`, {
        email,
        name,
        industries,
      });

      await this.$store.dispatch("init");

      await this.$router.push({
        path: "/companies",
      });
    },
    async click() {
      const { id } = this.$route.params;

      await http.delete(`companies/${id}`, {});

      await this.$router.push({
        path: "/companies",
      });
    },
  },
});
